import $ from "jquery";

import "./HomeSplash";
import "./Blazon";
import "./LinkStage";
import "./HomeGallery";

const styleClass = "invisible";

$(window).on("scroll", () => {
  const $logoLettering = $(".header__logo__lettering");
  const progress = $(window).scrollTop();
  if (progress !== undefined) {
    if (progress > 150) $logoLettering.removeClass(styleClass);
    else $logoLettering.addClass(styleClass);
  }
});
